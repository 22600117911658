import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import PDFLink from '../components/PDFLink';

import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';

export default function Pedagogique({ data }) {
  const [openedDescription, openDescription] = useState(null);
  const handleOpenDescription = slug => openDescription(slug === openedDescription ? null : slug);
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.tablet,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.desktop,
    },
  ];
  return <Fragment>
    <div className="container">
      <h1>Les contenus pédagogiques</h1>
      <div className="grid">
        <div className="column-half">
          <h2>La notice</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="notice_hd" title="La notice" />
          </div>
          <h2>Le Livret pour l'enseignant,<br />découpage par période</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_periode-1-hd" title="Période 1" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_periode-2-3-hd" title="Période 2-3" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_periode-4-hd" title="Période 4" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_periode-5-hd" title="Période 5" />
          </div>
        </div>
        <div className="column-half">
          <h2>Le Livret pour l'enseignant,<br />découpage par domaine d'apprentissage</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_maitrise-langue-hd" title="Maîtrise de la langue" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_explorer-le-monde-hd" title="Explorer le monde" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="livret_activites-artistiques-hd" title="Activités artistiques" />
          </div>
        </div>
      </div>
    </div>
    <div className="image-bg"><Img fluid={sources} /></div>
  </Fragment>
}

export const query = graphql`
query {
  mobileImage: file(relativePath: { eq: "pedagogique-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 342, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  tabletImage: file(relativePath: { eq: "pedagogique-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 588, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  desktopImage: file(relativePath: { eq: "pedagogique-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 788, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
}
`;
